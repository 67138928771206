<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Akt Sverka malumotlari</h3>
              </div>
            </div>
            <div class="card-body">
              <table>
                <tr>
                  <td colspan="6" class="text-center">
                    Акт Сверки Взаиморасчетов
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-left">
                    {{ getAktSverka.report_date }}
                  </td>
                  <td colspan="3" class="text-right">г.Ташкент</td>
                </tr>
                <tr>
                  <td colspan="6" class="text-center">
                    Мы нижеподписавшиеся, ООО "{{ getAktSverka.company_name }}"
                    в лице директора
                    <span v-if="getAktSverka.company_general_info == null">
                      Kiritilmagan
                    </span>
                    <span v-else>
                      {{ getAktSverka.company_general_info.company_direktor }}
                    </span>

                    с одной стороны, и
                    {{ getAktSverka.client_name }} в лице____________ с другой
                    стороны произвели акт сверку заиморасчетов по состоянию
                    {{ getAktSverka.report_start_date }} Договору №
                    {{ getAktSverka.contract_number }} от
                    {{ getAktSverka.contract_date }} и выявили следующее
                  </td>
                </tr>
                <tr>
                  <td rowspan="2">Дата</td>
                  <td rowspan="2">Содержание операции</td>
                  <td colspan="2">{{ getAktSverka.company_name }}</td>
                  <td colspan="2">{{ getAktSverka.client_name }}</td>
                </tr>
                <tr>
                  <td>Дебет</td>
                  <td>Кредит</td>
                  <td>Дебет</td>
                  <td>Кредит</td>
                </tr>
                <tr class="font-weight-bold">
                  <td class="text-left" colspan="2">
                    Сальдо на {{ getAktSverka.report_start_date }}
                  </td>
                  <td>{{ getAktSverka.debet_start_saldo_company }}</td>
                  <td>{{ getAktSverka.credit_start_saldo_company }}</td>
                  <td>{{ getAktSverka.debet_start_saldo_client }}</td>
                  <td>{{ getAktSverka.credit_start_saldo_client }}</td>
                </tr>

                <tr
                  v-for="itemm in getAktSverka.akt_sverka_body"
                  :key="itemm.id"
                >
                  <!-- <td>{{ getAktSverka.report_date }}</td> -->
                  <td>{{ itemm.oper_date }}</td>
                  <td>{{ itemm.reg_number }}</td>
                  <td>{{ itemm.debet_company }}</td>
                  <td>{{ itemm.credit_company }}</td>
                  <td>{{ itemm.debet_client }}</td>
                  <td>{{ itemm.credit_client }}</td>
                </tr>
                <tr>
                  <td class="text-left" colspan="2">Итого оборотов</td>
                  <td>{{ getAktSverka.sum_debet_end_saldo_company }}</td>
                  <td>{{ getAktSverka.sum_credit_company }}</td>
                  <td>{{ getAktSverka.sum_debet_client }}</td>
                  <td>{{ getAktSverka.sum_credit_end_saldo_client }}</td>
                </tr>

                <tr class="font-weight-bold">
                  <td class="text-left" colspan="2">
                    Сальдо на {{ getAktSverka.report_end_date }}
                  </td>
                  <td>{{ getAktSverka.debet_end_saldo_company }}</td>
                  <td>{{ getAktSverka.credit_end_saldo_company }}</td>
                  <td>{{ getAktSverka.debet_end_saldo_client }}</td>
                  <td>{{ getAktSverka.credit_end_saldo_client }}</td>
                </tr>
                <tr>
                  <th colspan="3" class="text-right">
                    {{ getAktSverka.company_name }}
                  </th>
                  <th colspan="3" class="text-right">
                    {{ getAktSverka.client_name }}
                  </th>
                </tr>
                <tr>
                  <td colspan="3" class="text-right">
                    <div v-if="getAktSverka.company_general_info == null">
                      Kiritilmagan
                    </div>
                    <div v-else>
                      <p>
                        р/с №
                        {{ getAktSverka.company_general_info.bank_account }}
                      </p>
                      <p>
                        отд. г. Ташкент Чаб "{{
                          getAktSverka.company_general_info.bank_name
                        }}"
                      </p>
                      <p>
                        МФО -
                        {{ getAktSverka.company_general_info.bank_mfo }} ИНН-{{
                          getAktSverka.company_general_info.company_inn
                        }}
                      </p>
                    </div>
                  </td>
                  <td colspan="3" class="text-right"></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {}
  },
  created() {
    this.$store.dispatch('kontragentContractAktSverkaID', this.$route.params.id)
  },
  computed: {
    getAktSverka() {
      const data = this.$store.getters.kontragentContractAktSverkaID

      return data
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kontragentlar' },
      { title: 'Akt sverka malumotlari' }
    ])
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
table td {
  text-align: center;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
}
</style>
